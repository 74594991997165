<template>
  <div class="box one">
    <h2 class="bold">업종별 관리 메모</h2>
    <ul class="memoList inputBox">
      <li>
        <p>웨딩장소</p>
        <input type="text" v-model="vendorPlace" />
      </li>
      <li>
        <p>스튜디오</p>
        <input type="text" v-model="vendorStudio" />
      </li>
      <li>
        <p>드레스/예복</p>
        <input type="text" v-model="vendorDress" />
      </li>
      <li>
        <p>헤어/메이크업/부케</p>
        <input type="text" v-model="vendorMakeup" />
      </li>
      <li>
        <p>허니문</p>
        <input type="text" v-model="vendorHoneymoon" />
      </li>
      <li>
        <p>예물</p>
        <input type="text" v-model="vendorGift" />
      </li>
      <li>
        <p>청첩장/식전영상</p>
        <input type="text" v-model="vendorInvitation" />
      </li>
      <li>
        <p>본식 원판/스냅</p>
        <input type="text" v-model="vendorWeddingPhoto" />
      </li>
      <li>
        <p>본식 영상</p>
        <input type="text" v-model="vendorWeddingVideo" />
      </li>
      <li>
        <p>한복/예단/폐백</p>
        <input type="text" v-model="vendorHanbok" />
      </li>
      <li>
        <p>주례/사회자</p>
        <input type="text" v-model="vendorEvent" />
      </li>
      <li>
        <p>기타</p>
        <input type="text" v-model="vendorEtc" />
      </li>
    </ul>
    <div class="buttonWrap">
      <button class="basic" @click="handleVendorMemo">수정</button>
    </div>
  </div>
</template>
<script>
import { fetchVendorMemoDetail, updateVendorMemo } from "@/api/admin";
export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      vendorPlace: "",
      vendorStudio: "",
      vendorDress: "",
      vendorMakeup: "",
      vendorHoneymoon: "",
      vendorGift: "",
      vendorInvitation: "",
      vendorWeddingPhoto: "",
      vendorWeddingVideo: "",
      vendorHanbok: "",
      vendorEvent: "",
      vendorEtc: "",
      vendorMemoId: "",
    };
  },
  watch: {
    userId(newVal) {
      if (newVal) {
        this.getVendorMemo();
      }
    },
  },
  mounted() {
    if (this.userId) {
      this.getVendorMemo();
    }
  },
  methods: {
    getVendorMemo() {
      fetchVendorMemoDetail(this.userId).then((res) => {
        if (res.data.status == 200) {
          if (res.data.data != null) {
            this.vendorPlace = res.data.data.place;
            this.vendorStudio = res.data.data.studio;
            this.vendorDress = res.data.data.dress;
            this.vendorMakeup = res.data.data.makeup;
            this.vendorHoneymoon = res.data.data.honeymoon;
            this.vendorGift = res.data.data.gift;
            this.vendorInvitation = res.data.data.invitation;
            this.vendorWeddingPhoto = res.data.data.weddingPhoto;
            this.vendorWeddingVideo = res.data.data.weddingVideo;
            this.vendorHanbok = res.data.data.hanbok;
            this.vendorEvent = res.data.data.event;
            this.vendorEtc = res.data.data.etc;
            this.vendorMemoId = res.data.data._id;
          }
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    handleVendorMemo() {
      let data = {
        place: this.vendorPlace,
        studio: this.vendorStudio,
        dress: this.vendorDress,
        makeup: this.vendorMakeup,
        honeymoon: this.vendorHoneymoon,
        gift: this.vendorGift,
        invitation: this.vendorInvitation,
        weddingPhoto: this.vendorWeddingPhoto,
        weddingVideo: this.vendorWeddingVideo,
        hanbok: this.vendorHanbok,
        event: this.vendorEvent,
        etc: this.vendorEtc,
        memoId: this.vendorMemoId,
      };
      updateVendorMemo(data).then((res) => {
        if (res.data.status == 200) {
          this.$notify({
            title: "Success",
            message: "업종별 메모 정보가 수정 되었습니다.",
            type: "success",
          });
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },
  },
};
</script>
