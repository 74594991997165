<template>
  <el-tabs v-model="activeName" class="tab2" @tab-click="handleTab">
    <el-tab-pane :label="`메모(${memoTotal})`" name="memo">
      <div class="box one memoInput">
        <div>
          <textarea
            v-model="memo"
            placeholder="메모 내용을 입력해 주세요."
          ></textarea>
          <button class="register" @click="makeMemo" :disabled="inactive">
            등록하기
          </button>
        </div>
      </div>
      <div class="box one">
        <table v-if="memoList.length > 0" class="mt0">
          <colgroup>
            <col style="width: 10%" />
            <col style="width: 10%" />
            <col style="width: 60%" />
            <col style="width: 15%" />
            <col style="width: 5%" />
          </colgroup>
          <tr>
            <th scope="col">번호</th>
            <th scope="col">작성자</th>
            <th scope="col">내용</th>
            <th scope="col">날짜</th>
            <th scope="col"></th>
          </tr>
          <tr v-for="(data, i) in memoList" :key="i">
            <td>
              {{ currentPage > 1 ? i + currentPage * 10 + 1 : i + 1 }}
            </td>
            <td>{{ data.author.username }}</td>
            <td class="prewrap">{{ data.content }}</td>
            <td>
              {{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}
            </td>
            <td style="text-align: center">
              <button class="delete" @click="deleteMemo(data._id)">삭제</button>
            </td>
          </tr>
        </table>
        <div class="pagination" v-if="memoList.length > 0">
          <el-pagination
            layout="prev, pager, next"
            :total="memoTotal"
            :page-size="10"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
        <NoData v-else />
      </div>
    </el-tab-pane>
    <el-tab-pane
      :label="`문의 내역(${qnaTotal})`"
      name="qna"
      v-if="isAdmin == false"
    >
      <div class="box one">
        <table v-if="qnaList.length > 0">
          <colgroup>
            <col style="width: 10%" />
            <col style="width: 15%" />
            <col style="width: 50%" />
            <col style="width: 15%" />
            <col style="width: 10%" />
          </colgroup>
          <tr>
            <th scope="col">번호</th>
            <th scope="col">카테고리</th>
            <th scope="col">제목</th>
            <th scope="col">날짜</th>
            <th scope="col">답변상태</th>
          </tr>
          <tr v-for="(data, i) in qnaList" :key="i">
            <td>
              {{ currentPage > 1 ? i + currentPage * 10 + 1 : i + 1 }}
            </td>
            <td>
              {{
                data.category == "RESERVATION"
                  ? "상품 이용 예약"
                  : data.category == "COMPOSITION"
                  ? "상품 구성 문의"
                  : data.category == "QUOTATION"
                  ? "견적 문의"
                  : data.category == "CONSULT"
                  ? "상담 요청"
                  : data.category == "ETC"
                  ? "기타"
                  : data.category
              }}
            </td>
            <td>
              <router-link
                :to="{ name: 'qnareply', query: { id: data._id } }"
                >{{ data.title }}</router-link
              >
            </td>
            <td>
              {{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}
            </td>
            <td
              :class="{
                waiting: data.isReply == false,
                done: data.isReply == true,
              }"
            >
              {{ data.isReply == true ? "답변완료" : "답변대기" }}
            </td>
          </tr>
        </table>
        <div class="pagination" v-if="qnaList.length > 0">
          <el-pagination
            layout="prev, pager, next"
            :total="qnaTotal"
            :page-size="10"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
        <NoData v-else />
      </div>
    </el-tab-pane>
    <el-tab-pane
      :label="`상담 내역(${consultTotal})`"
      name="consult"
      v-if="isAdmin == false"
    >
      <div class="box one">
        <table v-if="consultList.length > 0">
          <colgroup>
            <col style="width: 10%" />
            <col style="width: 30%" />
            <col style="width: 10%" />
            <col style="width: 10%" />
            <col style="width: 15%" />
            <col style="width: 15%" />
            <col style="width: 10%" />
          </colgroup>
          <tr>
            <th scope="col">번호</th>
            <th scope="col">상담 내용</th>
            <th scope="col">플래너</th>
            <th scope="col">결혼 예정일</th>
            <th scope="col">예식 지역</th>
            <th scope="col">날짜</th>
            <th scope="col">답변상태</th>
          </tr>
          <tr v-for="(data, i) in consultList" :key="i">
            <td>
              {{ currentPage > 1 ? i + currentPage * 10 + 1 : i + 1 }}
            </td>
            <td>
              <router-link
                :to="{ name: 'consultreply', query: { id: data._id } }"
                >{{ data.comment }}</router-link
              >
            </td>
            <td>{{ data.planner?.plannerName }}</td>
            <td>{{ moment(data.weddingDate).format("YYYY.MM.DD") }}</td>
            <td>{{ data.region }}</td>
            <td>
              {{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}
            </td>
            <td
              :class="{
                waiting: data.isReply == false,
                done: data.isReply == true,
              }"
            >
              {{ data.isReply == true ? "답변완료" : "답변대기" }}
            </td>
          </tr>
        </table>
        <div class="pagination" v-if="consultList.length > 0">
          <el-pagination
            layout="prev, pager, next"
            :total="consultTotal"
            :page-size="10"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
        <NoData v-else />
      </div>
    </el-tab-pane>
    <el-tab-pane
      :label="`찜 내역(${likesList.total})`"
      name="dib"
      v-if="isAdmin == false"
    >
      <div class="box one likesTable" v-if="likesList.total > 0">
        <table v-if="likesList.weddingPlaceLikes.length">
          <colgroup>
            <col style="width: 5%" />
            <col style="width: 15%" />
            <col style="width: 20%" />
            <col style="width: 25%" />
            <col style="width: 35%" />
          </colgroup>
          <tr></tr>
          <tr v-for="(data, i) in likesList.weddingPlaceLikes" :key="i">
            <td>
              <img :src="data.thumbnail" />
            </td>
            <td>웨딩 장소</td>
            <td>
              {{
                data.category == "HALL"
                  ? "웨딩홀"
                  : data.category == "HOTEL"
                  ? "호텔"
                  : data.category == "HOUSE"
                  ? "스몰/야외&amp;하우스웨딩"
                  : data.category == "MEETING"
                  ? "상견례"
                  : data.category == "BIRTHDAY"
                  ? "돌잔치"
                  : data.category == "TRADITION"
                  ? "전통 혼례"
                  : ""
              }}
            </td>
            <td>{{ data.title }}</td>
            <td>{{ data.region }}</td>
          </tr>
        </table>
        <table v-if="likesList.plannerLikes.length">
          <colgroup>
            <col style="width: 5%" />
            <col style="width: 15%" />
            <col style="width: 20%" />
            <col style="width: 25%" />
            <col style="width: 35%" />
          </colgroup>
          <tr v-for="(data, i) in likesList.plannerLikes" :key="i">
            <td>
              <img :src="data.images[0]" />
            </td>
            <td>플래너</td>
            <td>
              {{ data.plannerName }}
            </td>
            <td>
              {{
                data.plannerType == "DIRECTOR"
                  ? "디렉터"
                  : data.plannerType == "PLANNER"
                  ? "플래너"
                  : data.plannerType == "TOURPLANNER"
                  ? "투어플래너"
                  : ""
              }}
            </td>
            <td>{{ data.region.join() }}</td>
          </tr>
        </table>
        <table v-if="likesList.collectionLikes.length">
          <colgroup>
            <col style="width: 5%" />
            <col style="width: 15%" />
            <col style="width: 20%" />
            <col style="width: 25%" />
            <col style="width: 35%" />
          </colgroup>
          <tr v-for="(data, i) in likesList.collectionLikes" :key="i">
            <td>
              <img :src="data.thumbnail" />
            </td>
            <td>웨딩 컬렉션</td>
            <td>
              {{
                data.category == "STUDIO"
                  ? "사진"
                  : data.category == "DRESS"
                  ? "드레스/예복"
                  : data.category == "MAKEUP"
                  ? "헤어&#38;메이크업/부케"
                  : data.category == "HONEYMOON"
                  ? "허니문"
                  : data.category == "VIDEO"
                  ? "웨딩영상"
                  : data.category == "EVENT"
                  ? "주례&#38;사회/이벤트"
                  : data.category == "GIFT"
                  ? "예물"
                  : data.category == "HANBOK"
                  ? "한복/예단"
                  : data.category == "PYEBAEK"
                  ? "폐백&#38;이바지/청첩장"
                  : data.category == "BEAUTY"
                  ? "뷰티케어"
                  : data.category == "INTERIOR"
                  ? "가전/가구/인테리어"
                  : data.category == "CATERING"
                  ? "케이터링"
                  : ""
              }}
            </td>
            <td>{{ data.title }}</td>
            <td>{{ data.agency }}</td>
          </tr>
        </table>
        <table v-if="likesList.storeLikes.length">
          <colgroup>
            <col style="width: 5%" />
            <col style="width: 15%" />
            <col style="width: 20%" />
            <col style="width: 25%" />
            <col style="width: 35%" />
          </colgroup>
          <tr v-for="(data, i) in likesList.storeLikes" :key="i">
            <td>
              <img :src="data.thumbnail" />
            </td>
            <td>스토어</td>
            <td>
              {{
                data.category == "WEDDING"
                  ? "웨딩패키지"
                  : data.category == "BEAUTY"
                  ? "뷰티"
                  : data.category == "GIFT"
                  ? "답례품"
                  : data.category == "TRAVEL"
                  ? "여행"
                  : data.category == "ACTIVITY"
                  ? "액티비티"
                  : data.category == "CLASS"
                  ? "원데이클래스"
                  : data.category == "ETC"
                  ? "기타"
                  : ""
              }}
            </td>
            <td>{{ data.seller }}</td>
            <td>{{ data.title }}</td>
          </tr>
        </table>
      </div>
      <div class="box one" v-else>
        <NoData />
      </div>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import {
  createUserMemo,
  fetchUserMemoList,
  removeMemo,
  fetchQnAList,
  fetchConsultList,
  fetchUserLikesList,
} from "@/api/admin";
import NoData from "@/components/contents/Nodata";
import moment from "moment";

export default {
  components: { NoData },

  props: {
    userId: {
      type: String,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      moment: moment,
      activeName: "memo",
      memo: "",
      memoList: [],
      total: 0,
      consultTotal: 0,
      qnaTotal: 0,
      memoTotal: 0,
      inactive: false,
      qnaList: [],
      consultList: [],
      likesList: "",
    };
  },
  watch: {
    userId(newVal) {
      if (newVal) {
        this.getQnaList();
        this.getConsultList();
        this.getUserLikes();
        this.getUserMemos();
      }
    },
  },
  mounted() {
    if (this.userId) {
      this.getQnaList();
      this.getConsultList();
      this.getUserLikes();
      this.getUserMemos();
    }
  },
  methods: {
    handleTab() {
      this.total = 0;
      this.currentPage = 1;
      if (this.activeName == "memo") {
        this.memoList = [];
        this.getUserMemos();
      }
    },
    getUserMemos() {
      let params = {
        userId: this.userId,
        page: this.currentPage,
      };
      fetchUserMemoList(params).then((res) => {
        if (res.data.status == 200) {
          this.memoList = res.data.data;
          this.memoTotal = res.data.total;
          this.currentPage = Number(res.data.Page);
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },
    makeMemo() {
      if (this.memo == "") {
        this.$alert("내용을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      let data = {
        content: this.memo,
        user: this.userId,
      };
      this.inactive = true;
      createUserMemo(data).then((res) => {
        if (res.data.status == 200) {
          this.$notify({
            title: "Success",
            message: "메모가 등록 되었습니다.",
            type: "success",
          });
          this.memo = "";
          this.inactive = false;
          this.getUserMemos();
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
          this.inactive = false;
          return;
        }
      });
    },
    deleteMemo(id) {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      })
        .then(() => {
          removeMemo(id).then((res) => {
            if (res.data.status == 200) {
              this.$notify({
                title: "Success",
                message: "메모가 삭제 되었습니다.",
                type: "success",
              });
              this.getUserMemos();
            } else {
              this.$alert(res.data.message, {
                confirmButtonText: "확인",
                center: true,
              });
              return;
            }
          });
        })
        .catch(() => {});
    },
    getUserLikes() {
      let params = {
        userId: this.userId,
      };
      fetchUserLikesList(params).then((res) => {
        if (res.data.status == 200) {
          this.likesList = res.data;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    getConsultList() {
      let params = {
        page: this.currentPage,
        userId: this.userId,
      };
      if (this.adminType == "PLANNER") {
        params.plannerId = localStorage.getItem("plannerId");
      }
      fetchConsultList(params).then((res) => {
        if (res.data.status == 200) {
          this.consultList = res.data.data;
          this.consultTotal = res.data.total;
          this.currentPage = Number(res.data.Page);
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    getQnaList() {
      let params = {
        page: this.currentPage,
        userId: this.userId,
      };
      fetchQnAList(params).then((res) => {
        if (res.data.status == 200) {
          this.qnaList = res.data.data;
          this.qnaTotal = res.data.total;
          this.currentPage = Number(res.data.Page);
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },

    handleCurrentChange(val) {
      this.currentPage = val;
      if (this.activeName == "memo") {
        this.getUserMemos();
      }
    },
  },
};
</script>
